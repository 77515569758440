import {BasilSearchSolid} from '@/assets/icons/comm/BasilSearchSolid';
import {MaterialSymbolsDelete} from '@/assets/icons/comm/MaterialSymbolsDelete';
import BaseInput from '@/components/base/baseInput';
import useStateHooks from '@/pages/funds/currency/useStateHooks';
import {history} from '@@/core/history';
import {Image} from '@nextui-org/react';
import {useTranslation} from 'react-i18next';

export default () => {
    const {optionalCurrencies, url} = useStateHooks();

    const {t} = useTranslation();

    return (
        <>
            <div className="pt-4 flex pb-3 px-4 w-full fixed top-0 bg-background z-50">
                <div className="text-center flex-1">
                    <BaseInput
                        className="bg-backgroundAuxiliaryColor overflow-hidden h-[40px]"
                        startContent={
                            <BasilSearchSolid className=" text-iconFontColor text-xl"/>
                        }
                        placeholder={t('搜索')}
                        classNames={{
                            base: 'rounded-[10px] overflow-hidden h-[40px]',
                            inputWrapper:
                                'h-full  rounded-[10px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
                        }}
                    ></BaseInput>
                </div>
                <div
                    onClick={() => {
                        history.back();
                    }}
                    className="pt-2.5 text-primary pl-3 h-[100%] flex items-center whitespace-nowrap"
                >
                    {t('取消')}
                </div>
            </div>
            <div className="pt-[74px] px-4">
                <div>
                    <div className="mb-3">
                        <div className="flex justify-between">
              <span className="text-backContrastColor text-base">
                {t('历史记录')}{' '}
              </span>
                            <MaterialSymbolsDelete
                                className="text-auxiliaryTextColor"
                                width="1.3em"
                                height="1.3em"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="mb-3">
                        <div className="flex justify-between">
              <span className="text-backContrastColor text-base">
                {t('热门')}
              </span>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    {(optionalCurrencies as any[]).map((item) => (
                        <div
                            key={item.id}
                            onClick={() => {
                                history.replace(`${url}?alias=${item.alias}&id=${item.id}`);
                            }}
                        >
                            <div className="flex  justify-between items-center text-base mb-8  text-backContrastColor">
                                <div className="flex items-center">
                                    <Image
                                        className="w-7 h-7 rounded-full object-cover"
                                        src={item.icon}
                                    />
                                    <div>
                                        <div className="ml-3">{item.alias}</div>
                                        <div className="ml-3 text-auxiliaryTextColor text-xs">
                                            {item.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
