import {
  getOptionalCurrenciesApi,
  spotAndContractCurrenciesApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { useMount } from 'ahooks';
import { useMemo } from 'react';

export enum CurrencyOperateEnum {
  //划转
  TRANSFER = 'transfer',
  //现货充值
  SPOT_RECHARGE = 'spot_recharge',
  //合约充值
  CONTRACT_RECHARGE = 'contract_recharge',
}

export default () => {
  //取值
  const [urlState] = useUrlState<any>({});
  const { url, type } = urlState || {};
  //获取划转币种
  const { data: transferCurrency = [], run: getOptionalCurrenciesReq } = useReq(
    getOptionalCurrenciesApi,
    {},
  );
  //获取现货充值币种
  const {
    data: spotAndContractCurrencies = [],
    run: spotAndContractCurrenciesReq,
  } = useReq(spotAndContractCurrenciesApi, {});
  //获取合约充值币种
  useMount(() => {
    // if (type === CurrencyOperateEnum.TRANSFER) {
    //   getOptionalCurrenciesReq();
    //   return;
    // }
    spotAndContractCurrenciesReq({
      type: type === CurrencyOperateEnum.SPOT_RECHARGE ? '1' : '2',
    });
  });
  //当前可选币种
  const optionalCurrencies = useMemo(() => {
    switch (type) {
      case CurrencyOperateEnum.TRANSFER:
        return transferCurrency;
      case CurrencyOperateEnum.CONTRACT_RECHARGE:
      case CurrencyOperateEnum.SPOT_RECHARGE:
        return spotAndContractCurrencies;
      default:
        return [];
    }
  }, [transferCurrency, spotAndContractCurrencies, type]);
  return {
    optionalCurrencies,
    url,
  };
};
